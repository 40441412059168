<template>
<div id="bk">
    <el-row class="header">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 3 }" :lg="{ span: 16, offset: 4 }" :xl="{ span: 12, offset: 6 }">

            <img class="logo" src="../assets/logo.png"/>

            <div class="slogon" >口讯网提供私人讯息加密存储与转交服务</div>

            <div class="toolview">
                <div class="formitem">
                    <input class="getMemoEdit" v-model="downloadcode" v-on:keyup.enter="getMemo" placeholder="请输入留言编号"/>
                    <div class="getMemoBtn" @click.stop="getMemo">提取信息</div>
                </div>

                <div class="formitem">
                    <div class="formitem startMemo" @click.stop="writeMemo">存储信息</div>
                </div>

            </div>
        </el-col>
    </el-row>


    <el-row class="row1 ">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 4 }" :lg="{ span: 14, offset: 5 }" :xl="{ span: 14, offset: 5 }">
            <div class="introview">
                <div class="question">假如您独自外出发生意外无人知怎么办？</div>
                <p>口讯网帮您实现意外风险预期管理。您可以把未来去向和行动计划加密存储在口讯网，如果超过某个日期自己没有取消这个口讯，就让我们联系您指定的联系人，从而管控可能的意外风险。</p>
            </div>
        </el-col>
    </el-row>
    <el-row class="row2 ">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 4 }" :lg="{ span: 14, offset: 5 }" :xl="{ span: 14, offset: 5 }">
            <div class="introview">
                <div class="question">假如您发生意外微信钱包里的钱怎么办？</div>
                <p>
                    可以使用口讯网制定个人资产安全计划。</p>
                <p> 信息即资产，包括不限于各种金融账号、网店账号，游戏账号、社交账号，数字货币账号等等，它们是您的隐私，您不能提前告诉别人但也不想有事发生后自己的数字资产因无人知晓而白白湮灭。
                </p>
                <p>
                    传统的继承不能解决所有问题，因为您在意的人可能不知道您的随时都可能变动的所有账号，甚至不了解您这些账号的价值，他们需要一份清晰的指引。
                </p>
                <p>
                    这份指引需要您秘密保存在一个安全的地方，同时您也需要有人能帮您在某个时刻转交给指定的人，口讯网可以帮您完成。
                </p>
                <p>
                    您负责加密信息和指定联系人。口讯网负责存储和在指定时刻转交，联系人负责解密信息，获取您的指引。
                </p>
            </div>
            
        </el-col>
    </el-row>
    <el-row class="row3 ">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 4 }" :lg="{ span: 14, offset: 5 }" :xl="{ span: 14, offset: 5 }">
            <div class="introview">
                <div class="question">口讯网如何保证信息安全？</div>
                <p>
                    两把钥匙，二次加密，匿名存储。
                </p>
                <p>
                    信息安全是口讯网存在的前提，为此我们设计了一个尽可能完善的加密模式。
                </p>
                <p>
                    首先用户先用自己的密码加密信息，口讯网不保存用户的密码，只存储加密后的信息。
                </p>
                <p>
                    由于用户的密码强度可能不高，直接这么存储有被暴力破解的风险。
                </p>
                <p>
                    口讯网会再次用一个512位长度的密码和用户密码共同组成一个新密码加密用户加密后的信息，这样即使地球上最强大的计算机在可见的时间里也无法暴力破解。
                </p>
                <p>
                    由于我们不保存用户密码，口讯网只保存它自己那部分密码，这样就相当于用户一把钥匙，网站一把钥匙，只有两把钥匙同时一起使用才能还原出加密的信息。
                </p>
                <p>
                    另外口讯网不要求用户注册任何信息，没有人知道您曾经存储过信息就没有人能发起针对性的破解行动，您的信息会被悄悄淹没在数字海洋里。
                </p>
            </div>
            
        </el-col>
    </el-row>
    <el-row class="row4 ">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 4 }" :lg="{ span: 14, offset: 5 }" :xl="{ span: 14, offset: 5 }">
            <div class="introview">
                <div class="question">口讯网如何转交信息？</div>
                <p>
                    口讯网提供各种有效通讯方式供您选择联系您指定的联系人，甚至可以提供人工上门转交服务。
                </p>
                <p>
                    您可以设定多个联系人，如果上一个联系人无法联系上，或者在一定时限内没有取回信息时，我们会按您的要求联系下一个人，最大限度的避免信息被湮灭。
                </p>

            </div>
        </el-col>

    </el-row>
    <el-row class="row5 ">
        <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 4 }" :lg="{ span: 14, offset: 5 }" :xl="{ span: 14, offset: 5 }">
            <div class="introview">
                <div class="question" style="font-weight:400;color:#cb3a56;">警告</div>
                <p>
                    口讯网不提供任何法律认证的留言和遗嘱服务，不对留言内容的真实性提供任何保证，也不对留言的合法性负责。
                </p>
                <p>
                    口讯网会在资源许可的前提下按用户设定的联系方式通知联系人但不保证一定会通知到，也不能保证通知后联系人会来取留言。
                </p>
                <p>
                    口讯网目前是免费服务，存在您的留言有效期内服务中止的可能，中止服务会提前通告。
                </p>
            </div>
           

        </el-col>
        <div class="footer">
                <span>All right reserved</span>
                <el-link type="primary" href="https://beian.miit.gov.cn/">蜀ICP备2021013463号-3</el-link>
                <span>Email: office@kouxun.link</span>
        </div>
    </el-row>

</div>
</template>

<style scoped>
#bk {
    height: 100%;
    font-size: 18px;
}

.header {
    /* background-image: url('../assets/bk2.jpg'); */
}



.col {
    height: 100%;
    /* padding: 60px 0 60px;  */
    box-sizing: border-box;
}

.logo {
    display: block;
    width: 60px;
    height: 50px;
    margin: 10px auto;
    box-sizing: border-box;
    margin-top:30px;
}

.question {
    width:400px;
    font-size: 16px;
    font-weight: 300;
    color: #222;
    background-color: #758a99;   
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    border-left: 4px solid #db5a6b ;
    border-right: 4px solid #db5a6b ;
    margin:10px auto;
}

.footer {
    /* margin-top: -40px; */
    height: 40px;
    text-align: center;
    clear: both;
    color: #aaa;
    font-size: 14px;
}

.footer>a {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
}

.slogon {
    color: #fff;
    font-size: 0.3rem;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
}

.introview {
    position: relative;
    margin: 20px 20px;
    padding: 10px;
    box-sizing: border-box;
    /* border-radius: 10px; */
    /* height: 600px; */
    /* background: rgba(0, 0, 0, 0.3); */
}

.title {
    /* background: rgba(255, 120, 120, 0.4); */
    font-size: 32px;
    font-weight: 400;
    color: #000;
    padding: 10px 0px;
    font-family: "Microsoft YaHei", "微软雅黑", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", Arial, sans-serif;

}

.introview>ul {
    padding: 10px 20px;
}

.introview>ul>li {
    padding: 10px 0px;
}

.introview>p {
    font-size: 16px;
    color:#808080;
    margin: 0 auto;
    padding: 10px 0px;
}

.toolview {
    margin: 0 auto;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.formview {
    height: 350px;
    width: 450px;
    margin: 0 auto;
    /* background-color: rgba(0,0,0,0.1);
  border: 1px solid rgba(255, 255, 255, 0.5); */
    border-radius: 12px;
}

.formitem {

    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    /* border: 1px solid #ddd;  */
    font-family: Arial;
    font-size: 32px;
    text-shadow: 0px 1px 0px #263666;
    /* width:350px; */
    height: 60px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    line-height: 60px;
    display: flex;
    justify-content: center;
}

.startMemo {
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 24px;
    line-height: 60px;
    /*background: linear-gradient(#70787e, #4b545b);*/
    background: #16446F;
    box-sizing: border-box;

}

/* .startMemo:hover {
    background-color: transparent;
    background: rgba(0, 0, 0, 0.3);
} */

.startMemo:active {
    background: #496e91;
}

.getMemoEdit {
    flex: auto;
    background: #fff;
    border-left: transparent;
    border-top: transparent;
    border-bottom: transparent;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    font-family: Arial;
    font-size: 20px;
    color: #222;
    width: 320px;
    height: 60px;
    line-height: 60px;
    box-sizing: border-boxing;
    text-align: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    outline: none;
    border: 1px solid #aaa;
}

.getMemoEdit:focus {
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.06);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.06);
}

.getMemoBtn {
    flex: auto;
    width: 200px;
    height: 60px;
    color: #FFF;
    font-size: 18px;
    line-height: 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /*background: linear-gradient(#70787e, #4b545b);*/
    background:#16446F;
    box-sizing: border-box;
}

.getMemoBtn:active {
    background: #496e91;
}

.formitem>input::-webkit-input-placeholder {
    color: #aaa;
}

.formitem>input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaa;
}

.formitem>input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa;
}

.formitem>input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

.formitem>input:focus::-webkit-input-placeholder {
    color: transparent;
    /* transparent是全透明黑色(black)的速记法，即一个类似rgba(0,0,0,0)这样的值 */
}

/* Mozilla Firefox 4 to 18 */
.formitem>input:focus:-moz-placeholder {
    color: transparent;
}

/* Mozilla Firefox 19+ */
.formitem>input:focus::-moz-placeholder {
    color: transparent;
}

/* Internet Explorer 10+ */
.formitem>input:focus:-ms-input-placeholder {
    color: transparent;
}
</style>

<script>
export default {
    name: 'HomePage',
    components: {},
    data() {
        return {
            bInput: false,
            downloadcode: ''

        }
    },
    methods: {
        writeMemo() {
            console.log('write memo')
            this.$router.push('WriteMemo')
            // window.location.href= '/writememo'
        },
        getMemo() {
            console.log('get memo')
            if (this.downloadcode === '') {
                this.bInput = false
                return
            }
            // var reg = new RegExp('^[0-9]*

            var url = this.downloadcode
            if (url.indexOf('http') === -1) {
                this.$router.push({
                    path: 'getmemo',
                    query: {
                        id: this.downloadcode
                    }
                })
            } else {
                this.$router.push(url)
            }
        }

    }
}
</script>
