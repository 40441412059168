<template>
    <div id="bk">
        <el-dialog title="请根据密码提示输入正确密码" :before-close="onDlgClose" custom-class="mydialog"
            :visible.sync="bPasswordDialog">
            <el-form class="selectitem">
                <p class="passwordTip">{{ passwordTip }}</p>
                <el-form-item label="密码" required>
                    <el-input v-model="userPassword"></el-input>
                </el-form-item>

                <template v-if="captchaImg">
                    <div class="captcha">
                        <img :src="captchaImg" />
                        <br>
                        <el-input style='width:100px;' v-model="captchaToken"></el-input>
                        <el-button @click="onChangeCaptcha" size="small">换一个</el-button>
                    </div>
                </template>
            </el-form>

            <div class="toolbar">
                <el-button class='btn' type="primary" @click="onCancleGetMemo">取消</el-button>
                <el-button class='btn' type="primary" @click="onGetMemo">确认</el-button>
            </div>
        </el-dialog>
        <el-row class="row">
            <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 3 }"
                :lg="{ span: 16, offset: 4 }" :xl="{ span: 12, offset: 6 }">

                <div class="header">
                    <img src="../assets/logo.png">
                </div>
                <div class="content">
                    <div class="memo">
                       
                        <div class="memoTitle">
                            <div class="titleCell">
                                <p>编号：<span style="color:#F56C6C;">{{ memoID }}</span></p>
                                <p class="tip2">警告：本口讯只能打开一次，请在关闭窗口前自行保存重要信息</p>
                            </div>
                        </div>
                       

                        <quill-editor class="memoContent" ref="editor" :options="editorOption" v-model="memoContent">
                        </quill-editor>

                    </div>
                </div>
                <!-- <div style="flex:auto;"></div>
                <div class="footer">
                    <el-link type="primary" href="https://beian.miit.gov.cn/">蜀ICP备2021013463号-3</el-link>
                </div> -->
            </el-col>

        </el-row>

    </div>
</template>

<script>

//var host = window.baseUrl


//var crypto = require('crypto')
var COS = require('cos-js-sdk-v5');

import {
    GetMemo,
    VerifyUserPassword,
    GetCaptcha,
    EncryptedMemo,
    DecryptedMemo,
    SHA1,
    GetMemoOK
} from '../util'

var _cos = null


export default {
    name: 'get_memo',
    data() {
        return {
            memoID: '',
            captchaImg: '',
            passwordTip: '',
            userPassword: '',

            bPasswordDialog: false,
            memoContent: '',

            captchaToken: '',

            editorOption: {
                readOnly: true,
                modules: {
                    // toolbar: []
                }
            }
        }
    },
    mounted: async function () {
        var el = document.querySelector('.ql-container')
        el.classList.add('myeditor')

        console.log('getmemo id:', this.$route.query.id)
        this.memoID = this.$route.query.id
        let getMemoLoading = this.$loading({
            target: '#getmemo',
            lock: true,
            text: 'Loading...'
        })


        let r = await GetMemo(this.memoID)

        getMemoLoading.close()

        if (!r) {
            this.$msgbox({
                title: '没有找到指定ID的口讯'
            })
            return
        }
        let { captcha, passwordTip } = r
        this.captchaImg = 'data:image/gif;base64,' + Buffer.from(captcha.code).toString('base64')
        this.passwordTip = passwordTip
        this.bPasswordDialog = true

    },
    methods: {
        onDlgClose() {
            return;
        },
        onCancleGetMemo() {
            this.bPasswordDialog = false
            this.$router.push({
                path: '/'
            })
        },
        async onChangeCaptcha() {
            let data = await GetCaptcha(this.memoID ? this.memoID : '')
            let img = 'data:image/gif;base64,' + Buffer.from(data.code.data).toString('base64')
            //console.log("captcha:",Buffer.from(data.code.data).toString('base64'))
            if (data) {
                this.memoID = data.id
                this.captchaImg = img
                this.bEncryptDialog = true
            } else {
                this.$msgbox({
                    title: '无法访问服务器，请稍后重试'
                })
            }
        },
        async onGetMemo() {

            let sha1 = SHA1(this.userPassword)
            let sampleText = EncryptedMemo(sha1, this.memoID)
            console.log("sampletext:", sampleText)

            let r = await VerifyUserPassword(this.memoID, this.captchaToken, sampleText)
            if (!r) {
                this.$msgbox({
                    title: '服务器错误，请稍后再试'
                })
                return
            }
            //分几种情况：验证码错误，密码错误, 都需要重新输入验证码和密码
            if (r.code == 0) {
                this.$msgbox({
                    title: '无法回取留言',
                    message: r.message
                })

                this.$router.push({
                    path: '/'
                })

                //this.captchaImg = 'data:image/gif;base64,' + Buffer.from(r.captcha).toString('base64')
                return
            }

            //获得正确官方密码，以及下载授权
            let officePassword = r.officePassword
            let data = r.data
            var credentials = data.credentials;

            if (!credentials) {
                this.$msgbox({
                    title: '未获取下载授权，请重试',
                })
                this.$router.push({
                    path: '/'
                })
                return
            }

            _cos = new COS({
                // getAuthorization 必选参数
                getAuthorization: function (options, callback) {
                    callback({
                        TmpSecretId: credentials.tmpSecretId,
                        TmpSecretKey: credentials.tmpSecretKey,
                        SecurityToken: credentials.sessionToken,
                        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
                    });
                }
            })
            console.log("获取正确官方密码和下载授权，开始下载文件")

            let _that = this
            _cos.getObject({
                Bucket: 'myfile-1308181812',
                /* 填入您自己的存储桶，必须字段 */
                Region: 'ap-guangzhou',
                /* 存储桶所在地域，例如ap-beijing，必须字段 */
                Key: `${this.memoID}.txt`,
                /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                onProgress: function (progressData) {
                    console.log('下载进度：', JSON.stringify(progressData));
                }
            }, async function (err, data) {
                if (!err) {
                    try {
                        //console.log("下载得到原始数据：",data.Body)
                        let s = DecryptedMemo(officePassword + sha1, data.Body)
                        let s1 = DecryptedMemo(_that.userPassword, s)
                        //console.log("解密后的数据：", s1)

                        let r = await GetMemoOK(_that.memoID)
                        if(r){
                            _that.memoContent = s1
                            _that.bPasswordDialog = false
                        }else{
                            _that.$msgbox({
                                title: '网络错误，请重试',
                            })
                        }
                        
                    } catch (error) {
                        console.log("下载错误：",error)
                        _that.$msgbox({
                            title: '网络错误，请重试',
                        })
                    }

                } else {
                    console.log("下载错误：",err)
                    _that.$msgbox({
                        title: '下载错误，请重试',
                    })
                }

            });
        }

    }
}
</script>

<style scoped>
html, body {
  height: 100vi;
}
#bk {
    display: block;
    font-size: 18px;
}

.row {
    height: 100%;
}

.col {
    height: 100%;
    /* padding: 60px 0 60px;  */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}


.header>img {
    display: block;
    width: 60px;
    height: 50px;
    margin: 10px auto;
    box-sizing: border-box;
}

.title {
    width: 100px;
    margin: 0 auto;
    line-height: 56px;
    font-size: 36px;

}

.content {
    min-height: 100%;
    padding: 10px;
    flex:auto;

}


.footer {
    height: 40px;
    text-align: center;
    clear: both;
    color: #aaa;
    font-size: 14px;
}

.footer>a {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
}


.memo {
    background: #fff;
    min-height: 400px;
    border-radius: 10px;
}

#getmemo {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 20px;
    padding-top: 0px;
    background: #fff;
    box-sizing: border-box;
}

.logview {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}

.memotip {
    line-height: 40px;
    color: rgb(158, 50, 50);
    text-align: center;
    font-size: 12px;
    padding: 4px;
}

.memoTitle {
    display: flex;
    color: #888;
}

.titleCell {
    flex: auto;
    width: 100px;
    padding: 10px;
    ;
    font-size: 14px;
}

.titleCell>p {
    padding: 8px;
}

.memoContent {
    padding: 8px 12px;
    min-height: 400px;
    border-top: 1px solid #ddd;
    margin: 8px;
    overflow: auto;
    color:#333;
}

.memoFooter {
    height: 40px;
}

.loadview {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    color: rgba(225, 225, 225, 0.9);
    font-size: 20px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
}

.getMemoBtn {
    width: 80px;
    height: 30px;
    color: #ddd;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    /*background: linear-gradient(#70787e, #4b545b);*/
    background: rgba(255, 120, 120, 0.9);
    margin: 0 auto;

}

.getMemoBtn:blur {
    background: rgba(255, 120, 120, 0.4);
}

.getMemoBtn:active {
    position: relative;
    top: 1px;
}

.captcha {
    display: block;
    margin: 10px auto;
}


.inputedit {
    display: block;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-family: Arial;
    font-size: 20px;
    color: #fff;
    width: 320px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-boxing;
    text-align: center;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    outline: none;
    margin: 0 auto;
}

.inputedit:focus {
    background: rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.06);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.06);
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #ddd;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #ddd;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #ddd;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #ddd;
}

.tip {
    width: 320px;
    margin: 20px auto;
    color: red;
}

.tip2{
    background-color:#177cb0; 
    font-weight:200; 
    font-size:14px; 
    color:#f0fcff;
    border-radius: 4px;
}
.toolbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.passwordTip {
    background: #222;
    color: #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
}
</style>
