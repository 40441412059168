<script>


import {
  GetOfficePassword,
  GetCosInstance,
  GetCaptcha,
  SubmitMemo,
  VerifyDenationCode,
  EncryptedMemo,
  SHA1
} from '../util'


function getBytesLength(str) {
  let buf = Buffer.from(str)
  return buf.length


}

//const qr = require('qr-image')

var _taskID = null

export default (await import('vue')).defineComponent({
  name:"WriteMemo",
  data() {

    return {

      content: '',
      content1:'', //加密后的信息
      editorOption: {
        placeholder: '请在这里写你的留言...'
      },
      memoType:0,
      memoID: '',
      denationCode:'', //捐款id
      expireDate: new Date(Date.now() + 1000 * 60 * 30),

      userPassword: '',
      passwordTip: '',
      todoList: [
        //   {
        //     code: 1,
        //     title: '电子邮件',
        //     name: '',
        //     msg: ''
        // }
      ],
      step: 1,

      captchaImg: '',
      captchaToken: '',
      officePassword: '',


      bUploadDlg: false,
      uploadProgress: 0,
      uploadSpeed: '0kb',

      bEncryptDialog: false
    }
  },
  // get the current quill instace object.
  computed: {
    editor() {
      return this.$refs.editor.quill
    }
  },

  mounted() {
    var el = document.querySelector('.ql-container')
    el.classList.add('myeditor')
   
    // you can use current editor object to do something(quill methods)
    // console.log('this is current quill instance object', this.editor)
   
  },
  methods: {
    onDlgClose(){
      return;
    },
    ChecktodoList(index, item) {
      index = Number(index)
      // console.log("响应检查：",item)

      if (item.code === 1) { // 电子邮件

        let reg = /^([a-zA-Z0-9_-].?)+([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!reg.test(item.contact)) {
          this.$msgbox({
            title: '联系人设置错误',
            type: 'error',
            message: `检测到第${index + 1}条的联系人电子邮件地址不正确，请修改`
          })
          return false
        }
      } else if (item.code === 2) {
        let reg = /^((\[0\+]\d{2,3}-)?(0\d{2,3})-)?(\d{7,13})(-(\d{3,}))?$/
        if (!reg.test(item.contact)) {
          this.$msgbox({
            title: '联系人设置错误',
            type: "error",
            message: `检测到第${index + 1}条的联系人电话不合法，请修改`
          })
          return false
        }
      } else if (item.code == 3) {
        let reg = /^((\[0\+]\d{2,3}-)?(0\d{2,3})-)?(\d{7,13})(-(\d{3,}))?$/
        if (!reg.test(item.contact)) {
          this.$msgbox({
            title: '联系人设置错误',
            type: 'error',
            message: `检测到第${index + 1}条的联系人电话不合法，请修改`
          })
          return false
        }
      } else if (item.code == 4) {
        if (item.contact.length < 10) {
          this.$msgbox({
            title: '联系人设置错误',
            type: 'error',
            message: `检测到第${index + 1}条的联系人地址小于10个字符，请修改`
          })
          return false
        }
        if (item.contact.length > 1024) {
          this.$msgbox({
            title: '联系人设置错误',
            type: 'error',
            message: `检测到第${index + 1}条的联系人地址大于1024个字符，请修改`
          })
          return false
        }
      }
      if (!item.respondTime || isNaN(Number(item.respondTime))) {
        this.$msgbox({
          title: '响应时间错误',
          type: 'error',
          message: `请设置第${index + 1}条的联系人的响应时间`
        });

        return false
      }
      if (Number(item.respondTime) > 48) {
        this.$msgbox({
          title: '响应时间错误',
          type: 'error',
          message: `第${index + 1}条的联系人的响应时间超过48小时，请修改`
        });
        return false
      }

      return true
    },
    //准备执行用户加密，但
    async onSetPassword() {
      var n = getBytesLength(this.content)
      if (n === 0) {
        this.$msgbox({
          title: '请先输入留言'
        })
        return
      }
      if (n > 1024 * 1024 * 10) {
        this.$msgbox({
          title: '留言大小超过10M，请减少内容'
        })
        return
      }


      for (let index in this.todoList) {
        let item = this.todoList[index]
        if (!this.ChecktodoList(index, item)) {
          return
        }
      }

      let data = await GetCaptcha(this.memoID ? this.memoID : '')
         //console.log("captcha:",Buffer.from(data.code.data).toString('base64'))
      if (data) {
        let img = 'data:image/gif;base64,' + Buffer.from(data.code.data).toString('base64')

        this.memoID = data.id
        this.captchaImg = img
        this.captchaToken = ''
        this.bEncryptDialog = true
      } else {
        this.$msgbox({
          title: '无法访问服务器，请稍后重试'
        })
      }
    },
    
    async onEncrypt() {
      if (this.userPassword.length < 1) {
        this.$msgbox({
          title: '密码不能为空'
        })
        return
      }

      if (this.passwordTip.length < 1) {
        this.$msgbox({
          title: '密码谜语不能为空'
        })
        return
      }
      if (!this.captchaToken) {
        this.$msgbox({
          title: '请填写验证码'
        })
        return
      }

      
      this.bEncryptDialog = false

      let sha1 = SHA1(this.userPassword)

      let sampleText = EncryptedMemo(sha1, this.memoID) //加密ID作为检测用户密码输入是否正确的依据

      let expired = new Date(this.expireDate).getTime()
      let officePassword = await GetOfficePassword(this.memoID, this.captchaToken, expired, sampleText)
  
      if (!officePassword) {
        this.$msgbox({
          title: '无法访问服务器，请稍后再试'
        })
        return
      }

      if(officePassword=='验证码错误'){
        this.$msgbox({
          title: '验证码错误'
        })
        return
      }

      let content1  = EncryptedMemo(this.userPassword, this.content)

      officePassword += sha1

      content1 = EncryptedMemo(officePassword, content1)

      this.content1 = content1
      this.step = 2
    },

    async onUploadMemo() {
      console.log("上传")
      //先上传加密内容为文档
      const cos = GetCosInstance()
      if(!cos){
        return
      }
      let _that = this

      if(this.denationCode){
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        let r = await VerifyDenationCode(this.denationCode)
        loading.close()
        if(!r){
          _that.$msgbox({
            title: '捐款订单验证错误',
            message:'请重新输入正确订单号或者取消输入'
          })
          return
        }
      }
      cos.putObject({
        Bucket: 'myfile-1308181812',
        /* 填入您自己的存储桶，必须字段 */
        Region: 'ap-guangzhou',
        /* 存储桶所在地域，例如ap-beijing，必须字段 */
        Key: `${this.memoID}.txt`,
        /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        Body: this.content1,
        SliceSize: 1024 * 1024 * 5,
        /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
        onTaskReady:  function (taskId) {
          /* 非必须 */
          console.log("上传taskID:",taskId);
          _taskID = taskId
          _that.bUploadDlg = true
        },
        onProgress: function (info) {
          /* 非必须 */
         
          _that.uploadProgress = parseInt(info.percent * 10000) / 100;
          _that.uploadSpeed = parseInt(info.speed / 1024 / 1024 * 100) / 100;
          console.log("progress:",info)
        },
        
      },async function (err, data) {
        console.log('上传结果:',err || data);
        if (!err) {
       
          //通知后台完成上传
          var data2 = {
            id:_that.memoID,
            passwordTip: _that.passwordTip,
            todoList: JSON.stringify(_that.todoList),
          }
          let r = await SubmitMemo(data2)
   
          if(!r){
            _that.bUploadDlg = false
            _that.$msgbox({
              title: '提交留言失败，请稍后再试'
            })
          }
        }else{
          _that.bUploadDlg = false
          _that.$msgbox({
            title: '上传失败，请稍后再试'
          })
        }

        //_that.bUploadDlg = false
      });


    },

    onDeltodoList(index) {
      this.todoList.splice(index, 1)
    },

    onAddMailAct() {
      this.$msgbox({
        message: '内测阶段暂不对外开放'
      })
      
      return
      // this.todoList.push({
      //   code: 1,
      //   title: '电子邮件',
      //   name: '',
      //   contact: '',
      //   respondTime: ''
      // })

    },
    onAddSMSAct() {
      this.$msgbox({
        message: '内测阶段暂不对外开放'
      })
      return 
      // this.todoList.push({
      //   code: 2,
      //   title: '短信',
      //   name: '',
      //   contact: '',
      //   respondTime: ''
      // })
    },
    onAddCallAct() {
      this.$msgbox({
        message: '内测阶段暂不对外开放'
      })
      return 
      // this.todoList.push({
      //   code: 3,
      //   title: '电话',
      //   name: '',
      //   contact: '',
      //   respondTime: ''
      // })
    },
    onAddManualAct() {
      this.$msgbox({
        title: '内测阶段暂不对外开放'
      })
      return 
      // this.todoList.push({
      //   code: 4,
      //   title: '人工访问',
      //   name: '',
      //   contact: '',
      //   respondTime: ''
      // })
    },

    async onChangeCaptcha() {
      let data = await GetCaptcha(this.memoID ? this.memoID : '')
      let img = 'data:image/gif;base64,' + Buffer.from(data.code.data).toString('base64')
      //console.log("captcha:",Buffer.from(data.code.data).toString('base64'))
      if (data) {
        this.memoID = data.id
        this.captchaImg = img
        this.bEncryptDialog = true
      } else {
        this.$msgbox({
          title: '无法访问服务器，请稍后重试'
        })
      }
    },

   
    // onOpenMemo() {
    //   window.location.href = '/#/getmemo?id=' + this.memoID
    //   console.log('尝试打开留言：', window.location.href)
    // },

    onEditorChange(
     /* {
      editor,
      html,
      text
    }*/
    ) {
      // this.memoSize = getBytesLength(this.content)
    },
    onCancleUpload() {
      this.bUploadDlg = false
      if(!_taskID)return 
      const cos = GetCosInstance()
      if(!cos){
        return
      }
      cos.pauseTask(_taskID)
    },
    onUploadOK(){
      this.bUploadDlg = false
      this.$router.push({
        path: '/'
    })
    },
    onCopyID(){
      if (!navigator.clipboard) {
        // Clipboard API not available
        this.$msgbox({
          title: '无法拷贝ID',
          type: 'error',
          message: `请自行记住ID`
        })
        return
      }
      navigator.clipboard.writeText(this.memoID)
        .then(() => {
        this.$msgbox({
            title: '已经拷贝到剪贴板',
            type: 'success'
        })
        return
      })
      .catch(err => {
        console.log("err:",err)
        this.$msgbox({
          title: '无法拷贝到剪贴板',
          type: 'error'
        })
      })
    }
  }
})

</script>
<template>
    <div id="bk">
        <el-dialog title="现在加密你的信息" :before-close="onDlgClose" custom-class="mydialog" :visible.sync="bEncryptDialog">
            <el-form class="selectitem">
                <el-form-item label="请设定一个密码" required>
                    <el-input v-model="userPassword"></el-input>
                </el-form-item>
                <el-form-item label="请用谜语描述你的密码" required>
                    <el-input id="passwordTip" v-model="passwordTip" placeholder="比如：我曾专门让你记住的那一句话"></el-input>
                </el-form-item>
                <div class="selecttip">注意：我们会把谜语转交给联系人，这是对方获得解密密码的唯一线索，请确保对方能理解你的谜语，网站不保存你的密码。不要单纯使用手机号或其它数字做密码，它很容易被破解。
                </div>

                <template v-if="captchaImg">
                    <div class="captcha">
                        <img :src="captchaImg" />
                        <br>
                        <el-input style='width:100px;' v-model="captchaToken"></el-input>
                        <el-button @click="onChangeCaptcha" size="small">换一个</el-button>
                    </div>
                </template>
            </el-form>

            <div class="toolbar">
                <el-button class='btn' type="primary" @click="bEncryptDialog = false">取消</el-button>
                <el-button class='btn' type="primary" @click="onEncrypt">确认</el-button>
            </div>
        </el-dialog>
        <div class="uploadprogress" v-if="bUploadDlg">
            <div class='uploadcenter'>
                <block v-if="uploadProgress === 100">
                    <el-progress class="progressCircle" type="circle" :stroke-width="12" :width="200"
                        v-if="uploadProgress === 100" :percentage="uploadProgress" status="success"></el-progress>

                    <p class="uploadtip">请记住你的口讯ID: <span style="color:#F56C6C;">{{ memoID }}</span>,
                        你可以使用这个ID在转交之前通过访问自己口讯删除口讯。</p>
                    <div class="toolbar">
                        <el-button class="uploadbtn" type="success" @click="onCopyID" size="small">复制ID</el-button>
                        <el-button class="uploadbtn" type="success" @click="onUploadOK" size="small">确定</el-button>
                    </div>
                </block>
                <block v-else>
                    <el-progress class="progressCircle" type="circle" :stroke-width="12" :width="200"
                        :percentage="uploadProgress"></el-progress>
                    <div class="uploadtip">上传速度: {{ uploadSpeed }}</div>
                    <el-button class="uploadbtn" @click="onCancleUpload" type="danger" size="small">取消上传</el-button>
                </block>
            </div>
        </div>
        <el-row class="row">
            <el-col class="col" :xs="24" :sm="{ span: 20, offset: 2 }" :md="{ span: 18, offset: 3 }" :lg="{ span: 16, offset: 4 }"
                :xl="{ span: 16, offset: 4 }">

                <div class="header1">
                    <img class='logo' src="../assets/logo.png">
                    <!-- <p class='hearertip'>请在这里写下任何你想说的话</p>
                 <p class='hearertip'>我们会在你指定时间转交给你指定的人</p> -->
                </div>

                <div class="content" v-if="step == 1">
                    <quill-editor id="editor" ref="editor" :options="editorOption" :disabled='step > 1' v-model="content"
                        @change="onEditorChange($event)"></quill-editor>
                    <el-form>
                        <block>

                            <div class="selectitem">
                                <el-form-item label="请设置留言保留期限" style="font-weight: bold;">
                                    <el-date-picker v-model="expireDate" type="datetime"
                                        placeholder="选择日期时间"></el-date-picker>
                                </el-form-item>
                                <div class="selecttip"><i class="el-icon-warning" style="color:#F56C6C;"></i>
                                    最小保存期限是30分钟，到期后我们将按您的设定通知联系人或者删除</div>
                            </div>
                            <div class="selectitem">

                                <el-form-item label="请设定留言到期后的联系人" style="font-weight: bold;">

                                    <el-table :data="todoList" :border="true" stripe style="width: 100%">
                                        <el-table-column prop="title" label="联系类型" width="120"></el-table-column>
                                        <el-table-column prop="name" label="联系人称呼" width="160">
                                            <template slot-scope="scope">
                                                <input class="tableinput" v-model="scope.row.name" placeholder="（选填)" />
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="msg" label="联系方式" width="200">
                                            <template slot-scope="scope">
                                                <input class="tableinput" v-model="scope.row.contact" placeholder="（必填)" />
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="respondtime" label="响应时间" width="150">
                                            <template slot-scope="scope">
                                                <!--el-date-picker   class="tableinput" v-model="scope.row.respondtime"  type="datetime" placeholder="响应截至时间"></el-date-picker-->
                                                <input class="tableinput" type="digit" v-model="scope.row.respondTime"
                                                    placeholder="响应时限(小时）" oninput="value=value.replace(/[^\d]/g,'')" />
                                            </template>
                                        </el-table-column>
                                        <el-table-column width="100">
                                            <template slot-scope="scope">
                                                <el-button size="mini" type="danger"
                                                    @click.native.prevent="onDeltodoList(scope.$index, todoList)"> 删除
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <el-button @click="onAddMailAct" size="small">添加电邮联系人</el-button>
                                    <el-button @click="onAddSMSAct" size="small">添加短信联系人</el-button>
                                    <el-button @click="onAddSMSAct" size="small">添加社交媒体联系</el-button>
                                    <el-button @click="onAddCallAct" size="small">添加电话联系人</el-button>
                                    <el-button @click="onAddManualAct" size="small">添加人工访问</el-button>
                                    <br>

                                </el-form-item>

                                 
                            </div>

                            <div class="selectitem">
                                <!-- <el-steps class="step" :active="step" align-center>
                            <el-step title="" description=""></el-step>
                            <el-step title="" description=""></el-step>
                            <el-step title="" description=""></el-step>
                        </el-steps> -->
                                <div class="toolbar">
                                    <el-button type="primary" class="btn" :disabled="step != 1" @click="onSetPassword"
                                        size="small">加密</el-button>

                                    <!-- <el-button type="primary" round class="btn" :disabled="step!=3" @click="onTestMemo" size="small">{{testBtnText}}</el-button> -->
                                </div>
                            </div>

                        </block>
                        <div class="selectitem" v-if="memoType == 2">
                            <div class="selecttip">注意：我们将免费帮你保存信息24小时，超过时间会自动删除，您可以付费延长存储时间。</div>
                            <el-form-item label="请设定一个密码" required>
                                <el-input v-model="userPassword"></el-input>
                            </el-form-item>
                            <div class="selecttip">请您自己记住密码，我们只保存你加密后的信息，这也意味着我们只返回你加密后信息，解密方式采用标准的AES算法，你可以自己解密</div>
                            <div class="selectitem">
                              
                                <div class="toolbar">
                                    <el-button type="primary" class="btn" :disabled="step != 1" @click="onSetPassword2"
                                        size="small">加密</el-button>

                                    <!-- <el-button type="primary" round class="btn" :disabled="step!=3" @click="onTestMemo" size="small">{{testBtnText}}</el-button> -->
                                </div>
                            </div>
                        </div>
                    </el-form>

                </div>
                <div class="content1" v-if="step == 2">
                    <p class="memo_tip">您的信息经过两次加密如下所示，请点击上传按钮完成留言，口讯网将只存储加密后的内容</p>
                    <p class="memo_id">ID: <span style="color:#F56C6C;">{{ memoID }}</span></p>
                    <p class="memo_content">
                        {{ content1 }}
                    </p>
                    <!-- <div class="infoview">
                    <div class="infoitem" :key="item" v-for="item in infoList">{{item}}</div>
                    <p>你的信息经过两次加密变成如下：</p>
                <p>{{content1}}</p>
                </div> -->

                    <div class="selecttip"><i class="el-icon-warning" style="color:#F56C6C;"></i>
                        注意：口讯网提供免费但无保证的留言转交服务，如果你的留言很重要，请捐款支持我们。附带捐款证明的留言会得到优先保证。我们选择第三方服务来接受捐款，不识别和保留留言者身份。捐款请点击：<a
                            href="https://mianbaoduo.com" target="_blank">立即捐款</a>,完成后把订单号填在下面。
                    </div>

                    <div class="toolbar">
                        <!-- <el-button type="primary"  class="btn"  @click="step=1" size="small" >取消</el-button> -->
                        <input class="order" v-model="memoCode" placeholder="捐款订单号（选填)" />
                        <el-button type="primary" class="btn" @click="onUploadMemo" size="small">上传</el-button>
                    </div>
                </div>
                <div style="height:100px;"></div>

                <el-footer class="footer">
                    <span>All right reserved</span>
                    <el-link type="primary" href="https://beian.miit.gov.cn/">蜀ICP备2021013463号-3</el-link>
                    <span>Email:office@kouxun.link</span>
                </el-footer>

            </el-col>

        </el-row>

    </div>
</template>

<style scoped>
#bk {
    font-size: 18px;
}

el-dialog {
    min-width: 300px;
}

.row {
    height: 100%;
    display: flex;
}

.col {
    height: 100%;
    /* padding: 60px 0 60px;  */
    box-sizing: border-box;
}

.header1 {
    display: block;
    position: relative;
}

.logo {
    display: block;
    width: 40px;
    margin: 10px auto;
    box-sizing: border-box;
}

.hearertip {
    color: #ddd;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
}

.title {
    width: 100px;
    margin: 0 auto;
    line-height: 56px;
    font-size: 36px;

}

.label1 {
    font-weight: blod;
}

.content {
    flex: auto;
    min-height: 600px;
    padding: 10px;
    margin: 0 10px;
}

.content1 {
    flex: auto;
    min-height: 600px;
    margin: 0 10px;
    border: 1px solid #aaa;
}

.footer {
    position: relative;
    margin-top: -40px;
    height: 40px;
    text-align: center;
    clear: both;
    color: #ddd;
    font-size: 14px;
}

.footer>a {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
}

#editor {
    /* width: 90%; */
    /* height: 400px; */
    margin: 0px auto;
    background-color: white;
    box-sizing: border-box;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
}

.memoresult {
    /* width:300px; */
    margin: 10px auto;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 8px;
    padding: 20px 10px;
}

.memoresult>p {
    padding: 8px;
}

.memoresult>.id {
    color: red;
    text-align: center;
    font-size: 24px;
    background-color: #000;
    padding: 10px;
    margin: 0 auto;
}

.testTip {
    color: #aaa;
    font-size: 16px;
    padding: 8px;
}

.memosize {
    position: absolute;
    right: 20px;
    line-height: 56px;
    font-size: 24px;
    color: #ccc
}

.fee {
    position: absolute;
    left: 20px;
    line-height: 56px;
    font-size: 24px;
}

.writeview {
    position: relative;
    width: 100%;
    text-align: center;
}

.toolview {
    width: 100%;
    margin-top: 100px;
}

.selectitem {
    /* border: 1px solid #ddd; */
    padding: 10px;
    /* border-radius: 10px; */
    background-color: white;
}

.selecttip {
    color: #fff;
    font-size: 14px;
    text-align: left;
    background: #177cb0;
    padding: 4px;
    border-radius: 4px;
}

.box {
    margin: 10px 0;
    border: 1px solid #303133;
}

.AddAct {
    margin: 20px 0px;
}

.tableinput {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
    border: 0;
    outline: none;
}

.uploadprogress {
    width: 100%;
    height: 100%;
    background-color: #000;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1000;
}

.uploadcenter {
    position: relative;
    width: 500px;
    height: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progressCircle {
    left: 50%;
    transform: translateX(-50%);
}

.uploadcell {
    flex: auto;
    text-align: center;
    color: #fff;
    width: 100px;
    height: 40px;
    line-height: 40px;
}

.btn {
    width: 120px;
    height: 40px;
    margin: 10px auto;
}

.captcha {
    margin: 10px auto;
    border: 1px solid #ddd;
}

.infoview {
    width: 100%;
    border: 1px solid #ddd;
    color: #ddd;
    background: #222;
    border-radius: 8px;
    font-size: 10px;
    padding: 8px;
}

.infoitem {
    width: 100%;
    padding: 4px;
}

#passwordTip::-webkit-input-placeholder {
    color: #aaa;
}

#passwordTip::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #aaa;
}

#passwordTip:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #aaa;
}

#passwordTip:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #aaa;
}

.qrimage {
    display: table-cell;
    /*主要是这个属性*/
    vertical-align: middle;
    margin: 0 auto;
}

.toolbar {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.btn {
    flex: none;
    width: 100px;
    padding: 0 2px;
}

.order {
    flex: auto;
    max-width: 300px;
    margin: 8px;
    padding: 0 2px;
}

.memo_tip {
    width: 100%;
    border-bottom: 1px solid #aaa;
    font-size: 20px;
    color: #aaa;
    padding: 8px;
}

.memo_id {
    width: 100%;
    font-size: 24px;
    color: #aaa;
    padding: 10px;
}

.memo_content {
    width: 100%;
    font-size: 20px;
    min-height: 600px;
    color: #ddd;
    word-break: break-all;
    padding: 8px;
}

.uploadtip {
    margin: 10px auto;
    width: 100%;
    color: #ddd;
    text-align: center;
    font-size: 12px;
}

.uploadbtn {
    margin: 20px auto;
    display: flex;

}</style>
