import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'

import router from './router'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
// import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './plugins/element.js'

Vue.use(VueQuillEditor, {
  theme: 'snow'
})

window.onresize = setHtmlFontSize
function setHtmlFontSize () {
    const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
    const htmlDom = document.getElementsByTagName('html')[0]
    htmlDom.style.fontSize = htmlWidth / 10 + 'px'
    if (parseInt(htmlDom.style.fontSize) > 100) htmlDom.style.fontSize = 100 + 'px'
    else if (parseInt(htmlDom.style.fontSize) < 24) htmlDom.style.fontSize = 24 + 'px'
    console.log('html fontsize:', htmlDom.style.fontSize)
}
setHtmlFontSize()
console.log('NODE_ENV:%s baseUrl:%s', process.env.NODE_ENV, window.baseUrl)


Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
