'use strict'

const http = require('http')
var crypto = require('crypto')
var COS = require('cos-js-sdk-v5');
const _host = 'https://www.kouxun.link'


var _cos = null
export function GetCaptcha(id) {
  let url = _host + `/getcaptcha?id=${id}`

  return new Promise((resolve) => {
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let data = JSON.parse(this.response)
          if (data.code == 1) resolve(data.data)
          else resolve(null)
        } else {
          console.log("getcaptcha err:", this.response)
          resolve(null)
        }
      }
    }
  })
}

export function GetOfficePassword(id, code, expireDate, sampleText) {
  return new Promise((resolve) => {
    var url = _host + `/getpassword?id=${id}&code=${code}&date=${expireDate}&text=${sampleText}`
    console.log("url:", url)
    var xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        var data = JSON.parse(this.response);
        if (!data || data.code == 0) {
          console.log("获取官方密码错误：", data.message)
          resolve('验证码错误')
          return
        }
        data = data.data
        var credentials = data.credentials;
        if (!credentials) {
          console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
          resolve(null)
        }

        _cos = new COS({
          // getAuthorization 必选参数
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          }
        })
        resolve(data.password)
      }
      //resolve(null)
    }
    xhr.send();
  })
}

export function testCos() {
  let memoID = 12121
  let url = _host + `/sts?memoID=${memoID}`

  _cos = new COS({
    // getAuthorization 必选参数
    getAuthorization: function (options, callback) {
      var xhr = new XMLHttpRequest()
      xhr.open('get', url)
      // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
      xhr.send()
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            let data = JSON.parse(this.response)
            var credentials = data.credentials;
            if (!credentials) {
              console.error('credentials invalid')
              return
            }
            callback({
              TmpSecretId: credentials.tmpSecretId,
              TmpSecretKey: credentials.tmpSecretKey,
              SecurityToken: credentials.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          }
        }
      }

    }
  })

  _cos.putObject({
    Bucket: 'myfile-1308181812',
    /* 填入您自己的存储桶，必须字段 */
    Region: 'ap-guangzhou',
    /* 存储桶所在地域，例如ap-beijing，必须字段 */
    Key: `${memoID}.txt`,
    /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
    Body: 'this is test',
    SliceSize: 1024 * 1024 * 5,
    /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
    onTaskReady: function (taskId) {
      /* 非必须 */
      console.log("上传taskID:", taskId);
    },
    onFileFinish: function (err, data, options) {
      /* 非必须 */
      console.log(options.Key + '上传' + (err ? '失败' : '完成'));
    }
  }, function (err, data) {
    console.log(err || data);
  });


}
export function GetCosInstance() {
  return _cos
}

export function SubmitMemo(data) {
  var url = _host + '/submitmemo'
  return new Promise((resolve) => {
    var formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }

    var xhr = new XMLHttpRequest()
    xhr.open('post', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send(formData)
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let r = JSON.parse(this.response)
          if (r.code == 0) {
            resolve(null)
          } else resolve(r)
        }
        resolve(null)
      }
    }
  })
}
export function GetMemoOK(id) {
  var url = _host + '/getmemook?id=' + id

  return new Promise((resolve) => {
  
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let r = JSON.parse(this.response)
          if (r.code == 1) {
            resolve(true)
          } else resolve(false)
        }
        resolve(false)
      }
    }
  })
}
export function VerifyDenationCode(denationCode){
  var url = _host + '/verifyDenation?code=' + denationCode

  return new Promise((resolve) => {
  
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let r = JSON.parse(this.response)
          if (r.code == 1) {
            resolve(true)
          } else resolve(false)
        }
        resolve(false)
      }
    }
  })
}
export function GetMemo(id){
  if(!id)return
  var url = _host +'/getmemo?id='+id
  return new Promise((resolve)=>{
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let r = JSON.parse(this.response)
          if (r.code == 1) {
            resolve(r.data)
          } else resolve(null)
        }
        resolve(null)
      }
    }
  })
}

export function VerifyUserPassword (id, captcha, password) {
  return new Promise((resolve) => {
      var url = _host + `/verifyuser?id=${id}&code=${captcha}&password=${password}`
      var xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.send()
      xhr.onreadystatechange = function () {
          if (this.readyState === 4) {
              if (this.status === 200) resolve(JSON.parse(this.response))
              else resolve(null)
          }
      }
  })
}

export function GetMemoContent(id) {
  return new Promise((resolve) => {
    _cos.getObject({
      Bucket: 'myfile-1308181812',
      /* 填入您自己的存储桶，必须字段 */
      Region: 'ap-guangzhou',
      Key: id + '.txt',

      onProgress: function (progressData) {
        console.log(JSON.stringify(progressData));
      }
    }, function (err, data) {
      console.log(err || data.Body);
      if (err) {
        console.log("获取留言错误：", err)
        resolve('')
      }      
      else resolve(data.Body)

    });
  })
}

export function StartSubmitMemo(host, data) {
  return new Promise((resolve, reject) => {
    var url = host + '/creatememo'
    var formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }

    var xhr = new XMLHttpRequest()
    xhr.open('post', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send(formData)
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) resolve(JSON.parse(this.response))
        else reject(this.response)
      }
    }
  })
}

export async function GetCipher(host, id, captcha) {
  return new Promise((resolve, reject) => {
    http.get({
      path: host + `/getcipher?id=${id}&token=${captcha}`
    }, function (res) {
      res.setEncoding('utf8')
      res.on('data', function (data) {
        var r = JSON.parse(data)
        resolve(r)
      })
      res.on('error', function (err) {
        reject(err)
      })
    })
  })
}

export function EncryptedMemo(key, content) {
  var cipherTool = crypto.createCipher('aes-256-cbc', key)
  var encrypted = cipherTool.update(content, 'utf8', 'hex')
  encrypted += cipherTool.final('hex')
  return encrypted
}

export function DecryptedMemo(key, content) {
  try {
    var decipher = crypto.createDecipher('aes-256-cbc', key)
    var decrypted = decipher.update(content, 'hex', 'utf8')
    decrypted += decipher.final('utf8')
    return decrypted
  } catch (error) {
    return ''
  }
}


export function SHA1(text) {
  let m = crypto.createHash('sha1')
  return m.update(text).digest('hex')
}

export async function GetUploadToken(host, memoID, key, sha1) {
  return new Promise((resolve, reject) => {
    var url = host + '/getuploadtoken'
    var formData = new FormData()

    formData.append('id', memoID)
    formData.append('key', key)
    formData.append('sha', sha1)

    var xhr = new XMLHttpRequest()
    xhr.open('post', url)
    // xhr.setRequestHeader("Content-Type","application/x-www-form- urlencoded");
    xhr.send(formData)
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          resolve(JSON.parse(this.response))
        } else reject(this.response)
      }
    }
  })
}
export function NotifyUploadSuccess(host, memoID, sha1) {
  return new Promise((resolve, reject) => {
    http.get({
      path: host + `/uploadsuccess?id=${memoID}&sha=${sha1}`
    }, function (res) {
      res.setEncoding('utf8')
      res.on('data', function (data) {
        var r = JSON.parse(data)
        if (r.code === 1) resolve(true)
        else resolve(false)
      })
      res.on('error', function (err) {
        reject(err)
      })
    })
  })
}
export function MD5(text) {
  let m = crypto.createHash('md5')
  return m.update(text).digest('hex')
}

export function UploadMemoToQiniu(xhr, memoID, token, content, onProgress) {
  // console.log(memoID, token, content);
  var blob = new Blob([content], {
    type: 'text/plain'
  })
  return new Promise(function (resolve, reject) {
    var url = 'http://up-z2.qbox.me' // 'http://up-z2.qiniu.com/'
    var formData = new FormData()
    formData.append('key', memoID)
    formData.append('token', token)
    formData.append('file', blob)

    xhr.open('post', url)
    xhr.send(formData)
    xhr.upload.onprogress = onProgress
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) resolve(this.response)
        else reject(this.response)
      }
    }
  })
}

export function GetQiniuMemoDownloadUrl(host, memoID) {
  return new Promise(function (resolve, reject) {
    var url = host + '/getdownloadurl?id=' + memoID
    var xhr = new XMLHttpRequest()
    xhr.open('get', url)
    xhr.send()
    xhr.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          resolve(JSON.parse(this.response))
        } else reject(this.response)
      }
    }
  })
}

export async function DownloadMemo(host, memoID) {
  var r = await GetQiniuMemoDownloadUrl(host, memoID)
  if (r.code === 1) {
    var url = r.data.url
    var passwordtext = r.data.passwordtext
    var password = r.data.password
    console.log('download token:', url)
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.send()
      xhr.onreadystatechange = function () {
        if (this.readyState === 4) {
          if (this.status === 200) {
            console.log('下载内容：', this.response)
            var content = DecryptedMemo(password, this.response)
            console.log('下载后解密memo:', content)
            content = content.split(memoID)[0]
            resolve({
              content,
              passwordtext
            })
          } else reject(this.response)
        }
      }
    })
  }
}

// 计算七牛的hash，用来对比上传是否成功
export function CalcQiniuEtag(content) {
  var buffer = Buffer.from(content)

  // 以4M为单位分割
  var blockSize = 4 * 1024 * 1024
  var sha1String = []
  var prefix = 0x16
  var blockCount = 0

  var sha1 = function (content) {
    var sha1 = crypto.createHash('sha1')
    sha1.update(content)
    return sha1.digest()
  }

  var bufferSize = buffer.length
  blockCount = Math.ceil(bufferSize / blockSize)

  for (var i = 0; i < blockCount; i++) {
    sha1String.push(Buffer.from(sha1(buffer.slice(i * blockSize, (i + 1) * blockSize))))
  }

  if (!sha1String.length) {
    return ''
  }
  var sha1Buffer = Buffer.concat(sha1String, blockCount * 20)

  // 如果大于4M，则对各个块的sha1结果再次sha1
  if (blockCount > 1) {
    prefix = 0x96
    sha1Buffer = sha1(sha1Buffer)
  }

  sha1Buffer = Buffer.concat(
    [Buffer.from([prefix]), sha1Buffer],
    sha1Buffer.length + 1
  )

  return sha1Buffer.toString('base64')
    .replace(/\//g, '_').replace(/\+/g, '-')
}

