import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/home'
import WriteMemo from '@/components/writememo'
import GetMemo from '@/components/getmemo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/writememo',
    name: 'WriteMemo',
    component: WriteMemo
  },
  {
    path: '/getmemo',
    name: 'GetMemo',
    component: GetMemo
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
